<template>

  <b-row>

    <!-- Left Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >

      <b-badge
        variant="primary"
        class="d-block p-75 mb-1"
      >
        <span class="mb-0 text-white">Submission # {{ userInfo.id }}</span>
      </b-badge>

      <b-card
        no-body
      >

        <b-card-header class="d-flex justify-content-between align-items-center">
          <h5>
            {{ camperStudent(1) }} Profile
          </h5>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled">
            <li>
              <span class="align-middle">Name: {{ userInfo.full_name }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">DOB: {{ userInfo.extra_user_info.birth_date }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">Gender: {{ userInfo.extra_user_info.gender }}</span>
            </li>
            <li class="mt-25">
              <span class="align-middle">Parent ID:
                <b-badge
                  variant="light-primary"
                ># </b-badge>
              </span>
            </li>
          </ul>
        </b-card-body>

      </b-card>

    </b-col>

    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >

      <!-- Loading -->
      <b-card
        v-if="isLoading"
      >
        Loading...
      </b-card>

      <b-card
        v-else
        no-body
      >
        <ApplicationAddRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
        />

        <div
          class="card-footer"
        >
          <b-button
            variant="primary"
            class="mr-2"
            @click="showModal"
          >
            Accept
          </b-button>
          <b-button
            variant="danger"
            class="mr-3"
            @click="updApplicationStatus('Rejected')"
          >
            Reject
          </b-button>
        </div>
      </b-card>

    </b-col>

    <!-- Approval Changes -->
    <b-modal
      ref="approval-changes"
      centered
      ok-title="Save"
      cancel-variant="outline-secondary"
      size="lg"
      hide-footer
      hide-header
    >
      <div
        class="p-2 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          class="mb-2"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
            fill="black"
          />
          <mask
            id="mask0"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="74"
            height="74"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              width="80"
              height="80"
              fill="#FF9F43"
            />
          </g>
        </svg>
        <b-card-title
          class="mb-1 font-weight-bold"
          title-tag="h2"
        >
          Approve Charge?
        </b-card-title>
        <b-card-text class="mb-2">
          By approving this application the card on file will be charged the remaining application balance
        </b-card-text>
        <b-card-text class="mb-2">
          <b-form-checkbox
            v-model="withoutCharging"
            class="d-inline-flex justify-content-center align-items-center"
          >
            Accept without charging
          </b-form-checkbox>
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mx-1"
          @click="updApplicationStatus('Accepted')"
        >
          <span>Yes, Approve</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 159, 67, .15)'"
          variant="outline-warning"
          class="mx-1"
          @click="hideModal"
        >
          <span>Cancel</span>
        </b-button>
      </div>
    </b-modal>

    <!-- Payment Failed -->
    <b-modal
      ref="payment-failed"
      centered
      ok-title="Save"
      cancel-variant="outline-secondary"
      size="lg"
      hide-footer
      hide-header
    >
      <div
        class="p-2 text-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          class="mb-2"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
            fill="black"
          />
          <mask
            id="mask0"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="3"
            y="3"
            width="74"
            height="74"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.3335 39.9999C3.3335 60.3332 19.6668 76.6666 40.0002 76.6666C60.3335 76.6666 76.6668 60.3332 76.6668 39.9999C76.6668 19.6666 60.3335 3.33325 40.0002 3.33325C19.6668 3.33325 3.3335 19.6666 3.3335 39.9999ZM10.0002 39.9999C10.0002 23.3333 23.3335 9.99992 40.0002 9.99992C56.6668 9.99992 70.0002 23.3333 70.0002 39.9999C70.0002 56.6666 56.6668 69.9999 40.0002 69.9999C23.3335 69.9999 10.0002 56.6666 10.0002 39.9999ZM43.3335 39.9999V26.6666C43.3335 24.6666 42.0002 23.3333 40.0002 23.3333C38.0002 23.3333 36.6668 24.6666 36.6668 26.6666V39.9999C36.6668 41.9999 38.0002 43.3333 40.0002 43.3333C42.0002 43.3333 43.3335 41.9999 43.3335 39.9999ZM43.0002 54.6666C43.0002 54.9999 42.6668 55.3333 42.3335 55.6666C41.6668 56.3333 41.0002 56.6666 39.6668 56.6666C38.6668 56.6666 38.0002 56.3333 37.3335 55.6666C36.6668 54.9999 36.3335 54.3333 36.3335 53.3333C36.3335 52.9427 36.4479 52.6666 36.5426 52.4378C36.6096 52.2761 36.6668 52.138 36.6668 51.9999C36.6668 51.6666 37.0002 51.3333 37.3335 50.9999C38.3335 49.9999 39.6668 49.6666 41.0002 50.3333C41.1668 50.3333 41.2502 50.4166 41.3335 50.4999C41.4168 50.5832 41.5002 50.6666 41.6668 50.6666C42.0002 50.6666 42.3335 50.9999 42.3335 50.9999C42.5002 51.1666 42.5835 51.3332 42.6668 51.4999C42.7502 51.6666 42.8335 51.8332 43.0002 51.9999C43.3335 52.3333 43.3335 52.9999 43.3335 53.3333C43.3335 53.4999 43.2502 53.7499 43.1668 53.9999C43.0835 54.2499 43.0002 54.4999 43.0002 54.6666Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0)">
            <rect
              width="80"
              height="80"
              fill="#FF9F43"
            />
          </g>
        </svg>
        <b-card-title
          class="mb-1 font-weight-bold"
          title-tag="h2"
        >
          Missing Payment Method
        </b-card-title>
        <b-card-text class="mb-2">
          The application is missing Credit Card information, all charges will have to be inputted manually
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mx-1"
          @click="updApplicationStatus('Accepted')"
        >
          <span>Yes, Accept Anyway</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 159, 67, .15)'"
          variant="outline-warning"
          class="mx-1"
          @click="hideModal"
        >
          <span>Cancel</span>
        </b-button>
      </div>
    </b-modal>

  </b-row>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BBadge, BButton, BCardText, BCardTitle, VBModal, BFormCheckbox,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/applications/applicationsStoreModule'
import useApplicationView from '@/views/admin/applications/application-view/useApplicationView'
import router from '@/router'

import ApplicationAddRow from '@/views/admin/applications/application-view/ApplicationViewRow.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,
    BCardText,
    BCardTitle,
    BFormCheckbox,

    ApplicationAddRow,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [formBuilderPreparations],
  data() {
    return {
      isLoading: true,
      groupDetail: {},
      setProgram: null,
      type: null,
      fieldsList: [],
      defaultFieldsList: [],
      userInfo: {
        extra_user_info: {},
      },
      fieldsValue: [],
      applicationStatus: null,
      withoutCharging: false,
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-applications'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      updateApplicationStatus,
      fetchApplication,
      fetchProgram,
      fetchForm,
    } = useApplicationView()

    return {
      updateApplicationStatus,
      fetchApplication,
      fetchProgram,
      fetchForm,
    }
  },
  watch: {
    setProgram(val) {
      if (!val) return

      this.fetchForm(val.form_id)
        .then(response => {
          this.defaultFieldsList = response.fields.map(row => ({
            id: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.id,
            field_id: row.id,
            value: this.fieldsValue.filter(item => item.field_id === row.id)[0]?.result,
            label: row.label,
            name: row.name,
            options: this.designateOptions(row.type, row.options),
            type: this.transformField(row.type),
            required: row.required,
            disabled: true,
          }))
          this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))

          this.isLoading = false
        })
    },
  },
  async created() {
    this.type = router.currentRoute.params.type
    const applicationID = router.currentRoute.params.id
    let programID = null

    if (applicationID) {
      await this.fetchApplication(applicationID).then(response => {
        this.applicationStatus = response.status
        programID = response.program_id
        this.userInfo = response.user
        this.fieldsValue = response.application_fields
      })
    }

    if (programID) {
      await this.fetchProgram(programID).then(response => {
        this.setProgram = response
        this.setProgram.form_id = response.forms.filter(form => form.type === this.type)[0]?.id
      })
    }
  },
  methods: {
    showModal() {
      if (this.applicationStatus === 'Payment Failed') {
        this.$refs['payment-failed'].show()
      } else {
        this.$refs['approval-changes'].show()
      }
    },
    hideModal() {
      this.$refs['approval-changes'].hide()
    },
    async updApplicationStatus(status) {
      const queryParams = {
        status,
        without_charging: this.withoutCharging,
      }
      await this.updateApplicationStatus(router.currentRoute.params.id, queryParams)
      this.$router.push({ name: 'admin-applications' })
    },
  },
}
</script>

<style scoped>
</style>
